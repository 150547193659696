/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        $('.dropdown-toggle').click(function() {
			if($(window).width()>=996) {
				var lien = $(this).attr('href');
				if(lien != undefined) {
					window.location.assign(lien);
				}
				else return false;
			}
        });
        
        $('.equal-height').matchHeight({byRow: false});
        
        $('.equal-height-row').matchHeight({byRow: true});
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(".chiffre-counter").appear(function() {
			var id = $(this).attr('id');
			var suffixe = $(this).attr('data-suffixe');
	        var chiffre = $(this).attr('data-counter');
	        var prefixe = $(this).attr('data-prefixe');
	        var compteur = new CountUp(id, 0, chiffre, 0, 2.5, {separator: ' ', suffix: suffixe, prefix: prefixe});
			compteur.start();
        });

		$('.owl-clients').owlCarousel({
		    loop:true,
		    items: 12,
		    dotsEach: 1,
		    autoplay: 1,
		    autoplayTimeout: 2500,
			margin: 20,
		    smartSpeed: 1000,
		    autoplayHoverPause: false,
		    autoWidth: true,
		});
		
		$('.owl-galerie').owlCarousel({
		    loop:true,
		    items: 3,
		    dotsEach: 1,
		    autoplay: 1,
		    autoplayTimeout: 2500,
		    smartSpeed: 1000,
		    autoplayHoverPause: false,
		    autoWidth: false,
		    margin: 20,
		    center: true,
		    nav: true,
		    navText: ['<i class="fa fa-chevron-circle-left"></i>','<i class="fa fa-chevron-circle-right"></i>'],
		    
			  responsive: {
				  0 : {
					  items: 1
					  },
				  768 : {
					  items: 2
					  },
				  992 : {
					  items: 3
					  },
			  },
		});
		
		$('.chocolat-parent').Chocolat();

		$('#carte').smoothZoom({
			width: '100%',
			height: '600px',
			responsive: true,
			responsive_maintain_ratio: true
		});

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
		var feed = new Instafeed({ get: 'user', userId: '5697767183', accessToken: '5697767183.1677ed0.13be2536cbaf4a73bf05256c394f35bb', limit:6, template:'<a target="_blank" href="{{link}}" class="instablock"><img src="{{image}}" /></a>', resolution: 'thumbnail'});
		feed.run();

		var configProfile = {
		  "profile": {"screenName": 'Mosellefibre'},
		  "domId": 'tweets-container',
		  "maxTweets": 2,
		  "enableLinks": true, 
		  "showUser": false,
		  "showTime": true,
		  "showImages": false,
		  "lang": 'fr'
		};
		twitterFetcher.fetch(configProfile);		


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_zoom': {
      finalize: function() {

		$('#carte').smoothZoom({
			width: '100%',
			height: '600px',
			responsive: true,
			responsive_maintain_ratio: true
		});

      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_map': {
      finalize: function() {

		var maplace = new Maplace({

		    map_options: {
		        set_center: [49.133333, 6.166667],
		        zoom: 8
		    }
		    

		}).Load();
		
		var ctaLayer = new google.maps.KmlLayer({
			//url: domain+'/app/themes/mfibre/kml/doc.kml'
			//url: 'https://raw.githubusercontent.com/mapbox/Simple-KML/master/sample/example.kml'
			url: 'https://moselle-fibre.fr/app/uploads/kml/doc.kml'
			//url: 'https://www.webidea-creation.fr/app/uploads/kml/doc.kml'
		});
		ctaLayer.setMap(maplace.oMap);
		
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
